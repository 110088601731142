import { faArrowUp, faChartArea, faCheckCircle, faFileCircleCheck, faFolder, faGears, faHardDrive, faHeadset, faMousePointer, faShield } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useState } from 'react'
import Footer from '../../components/Footer/Footer'
import { SiteNavigation } from '../../components/Navigation/SiteNavigation'
import './HostingServices.css'
import serverHeaderImage from '../../images/design/server-05.svg'
import joomla_app from '../../images/webhost_apps/joomla.png'
import laravel_app from '../../images/webhost_apps/laravel.png'
import mybb_app from '../../images/webhost_apps/mybb.png'
import phpbb_app from '../../images/webhost_apps/phpbb.png'
import whcms_app from '../../images/webhost_apps/whcms.png'
import sitepad_app from '../../images/webhost_apps/sitepad.gif'
import wp_app from '../../images/webhost_apps/wp.png'
import abante_app from '../../images/webhost_apps/abante.png'
import owncloud_app from '../../images/webhost_apps/owncloud.png'
import { Helmet } from 'react-helmet-async'

export const HostingServices = () => {

    return (
        <div>
            <Helmet>
                <title>Centnodes | Shared Hosting</title>
                <meta
                    name="description"
                    content="Shared hosting is an affordable web hosting option where your website shares server resources with other sites. It's perfect for small websites with moderate traffic that prioritize cost-effectiveness and convenience over extensive control and scalability."
                />;
            </Helmet>
            <SiteNavigation />
            <div className="border-bottom py-4">
                <div className="container ">
                    <div className="row align-items-center mt-5 ">
                        <div className="col-md-5 d-flex flex-column">
                            <span className="h5">Shared Hosting</span>
                            <span className="h2 mt-2">Shared Hosting from Centnodes.</span>
                            <p className="mt-2">Shared hosting is an affordable web hosting option where your website shares server resources with other sites. It's perfect for small websites with moderate traffic that prioritize cost-effectiveness and convenience over extensive control and scalability.</p>
                            <div className="btn-group">
                                <a href="#packages" className="btn btn-primary rounded m-1">See packages</a>
                                <a href="/help" className="btn btn-outline-primary rounded m-1">Contact Us</a>
                            </div>
                        </div>
                        <div className="col-md-7 d-flex justify-content-center align-items-center mt-5 mt-xl-0">
                            <div className="content-img"><img src={serverHeaderImage} alt="server" /></div>
                        </div>
                    </div>
                </div>
            </div>
            <section className=" bg-white p-4 text-center" id="plans">
                <div className="container d-flex mt-5  flex-column align-items-center">
                    <h2 className="h2">Shared Hosting</h2>
                    <p>Choose an ideal plan for your business or idea and put your website online today</p>
                    <div className="row text-center price price-modern card-hover-bg">
                        <div className="col border m-2 bg-light box-shadow hvr-grow">
                            <div id="packages" className="card-header">
                                <span className="h5 my-0 font-weight-normal">Webhosting I</span>
                                <p className="mt-2 mb-1 text-center">When you need just one site.</p>
                                <span className="card-title pricing-card-title mb-0 h1">&euro;1,99</span>
                                <small className="text-muted">/ mo</small>
                            </div>
                            <a href="https://my.centnodes.com/products/hosting" className="btn btn-skyblue">Choose Webhosting I</a>
                            <div className="card-body">
                                <p className="text-start mt-1 mb-1 font-regular">Top Features</p>
                                <ul className="list-unstyled mt-3 mb-4 text-left">
                                    <li><FontAwesomeIcon icon={faCheckCircle} color={'#83c183'} /><span>  Linux Website Hosting.</span></li>
                                    <li><FontAwesomeIcon icon={faCheckCircle} color={'#83c183'} /><span>  5GB SSD Storage</span></li>
                                    <li><FontAwesomeIcon icon={faCheckCircle} color={'#83c183'} /><span>  Unlimited monthly traffic</span></li>
                                    <li><FontAwesomeIcon icon={faCheckCircle} color={'#83c183'} /><span>  Unlimited Subdomains</span></li>
                                    <li><FontAwesomeIcon icon={faCheckCircle} color={'#83c183'} /><span>  Website Builder</span></li>
                                    <li><FontAwesomeIcon icon={faCheckCircle} color={'#83c183'} /><span>  Free SSL Certificate</span></li>
                                    <li><FontAwesomeIcon icon={faCheckCircle} color={'#83c183'} /><span>  +400 Apps Installer</span></li>
                                    <li><FontAwesomeIcon icon={faCheckCircle} color={'#83c183'} /><span>  Control Panel: Cent OS Web Panel</span></li>
                                    <li><FontAwesomeIcon icon={faCheckCircle} color={'#83c183'} /><span>  Daily Backups</span></li>
                                </ul>
                                <p className="text-start mt-1 mb-1 font-regular">Free Includes</p>
                                <ul className="list-unstyled mt-3 mb-4 text-start">
                                    <li><FontAwesomeIcon icon={faCheckCircle} color={'#83c183'} /><span>  Free DDOS Protection Included</span></li>
                                    <li><FontAwesomeIcon icon={faCheckCircle} color={'#83c183'} /><span>  Free SSL Certificate<i className="cci-question-01 d-flex ms-2" data-bs-trigger="hover focus" data-bs-toggle="popover" data-bs-content="Get your free SSL certificate when choosing this hosting plan" data-bs-original-title="Certificate SSL Free"></i></span></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col border m-2 bg-light box-shadow hvr-grow">
                            <div className="card-header">
                                <span className="h5 my-0 font-weight-normal">Webhosting II</span>
                                <p className="mt-2 mb-1 text-center">For those running multiple sites.</p>
                                <span className="card-title pricing-card-title mb-0 h1">&euro;4,99</span>
                                <small className="text-muted">/ mo</small>
                            </div>
                            <a href="https://my.centnodes.com/products/hosting" className="btn btn-skyblue">Choose Webhosting II</a>
                            <div className="card-body">
                                <p className="text-start mt-1 mb-1 font-regular">Top Features</p>
                                <ul className="list-unstyled mt-3 mb-4 text-left">
                                    <li><FontAwesomeIcon icon={faCheckCircle} color={'#83c183'} /><span>  Linux Website Hosting.</span></li>
                                    <li><FontAwesomeIcon icon={faCheckCircle} color={'#83c183'} /><span>  20GB SSD Storage</span></li>
                                    <li><FontAwesomeIcon icon={faCheckCircle} color={'#83c183'} /><span>  Unlimited monthly traffic</span></li>
                                    <li><FontAwesomeIcon icon={faCheckCircle} color={'#83c183'} /><span>  Unlimited Subdomains</span></li>
                                    <li><FontAwesomeIcon icon={faCheckCircle} color={'#83c183'} /><span>  Website Builder</span></li>
                                    <li><FontAwesomeIcon icon={faCheckCircle} color={'#83c183'} /><span>  Free SSL Certificate</span></li>
                                    <li><FontAwesomeIcon icon={faCheckCircle} color={'#83c183'} /><span>  +400 Apps Installer</span></li>
                                    <li><FontAwesomeIcon icon={faCheckCircle} color={'#83c183'} /><span>  Control Panel: Cent OS Web Panel</span></li>
                                    <li><FontAwesomeIcon icon={faCheckCircle} color={'#83c183'} /><span>  Daily Backups</span></li>
                                </ul>
                                <p className="text-start mt-1 mb-1 font-regular">Free Includes</p>
                                <ul className="list-unstyled mt-3 mb-4 text-start">
                                    <li><FontAwesomeIcon icon={faCheckCircle} color={'#83c183'} /><span>  Free DDOS Protection Included</span></li>

                                    <li><FontAwesomeIcon icon={faCheckCircle} color={'#83c183'} /><span>  Free SSL Certificate<i className="cci-question-01 d-flex ms-2" data-bs-trigger="hover focus" data-bs-toggle="popover" data-bs-content="Get your free SSL certificate when choosing this hosting plan" data-bs-original-title="Certificate SSL Free"></i></span></li>
                                </ul>
                            </div>
                        </div>

                        <div className="col border m-2 bg-light box-shadow hvr-grow">
                            <div className="card-header">
                                <span className="h5 my-0 font-weight-normal">Webhosting III</span>
                                <p className="mt-2 mb-1 text-center">Privacy and security features.</p>
                                <span className="card-title pricing-card-title mb-0 h1">&euro;14,99</span>
                                <small className="text-muted">/ mo</small>
                            </div>
                            <a href="https://my.centnodes.com/products/hosting" className="btn btn-skyblue">Choose Webhosting III</a>
                            <div className="card-body">
                                <p className="text-start mt-1 mb-1 font-regular">Top Features</p>
                                <ul className="list-unstyled mt-3 mb-4 text-left">
                                    <li><FontAwesomeIcon icon={faCheckCircle} color={'#83c183'} /><span>   Linux Website Hosting.</span></li>
                                    <li><FontAwesomeIcon icon={faCheckCircle} color={'#83c183'} /><span>  50GB of Storage</span></li>
                                    <li><FontAwesomeIcon icon={faCheckCircle} color={'#83c183'} /><span>  Unlimited monthly traffic</span></li>
                                    <li><FontAwesomeIcon icon={faCheckCircle} color={'#83c183'} /><span>  Unlimited Subdomains</span></li>
                                    <li><FontAwesomeIcon icon={faCheckCircle} color={'#83c183'} /><span>  Website Builder</span></li>
                                    <li><FontAwesomeIcon icon={faCheckCircle} color={'#83c183'} /><span>  Free SSL Certificate</span></li>
                                    <li><FontAwesomeIcon icon={faCheckCircle} color={'#83c183'} /><span>  +400 Apps Installer</span></li>
                                    <li><FontAwesomeIcon icon={faCheckCircle} color={'#83c183'} /><span>  Control Panel: Cent OS Web Panel</span></li>
                                    <li><FontAwesomeIcon icon={faCheckCircle} color={'#83c183'} /><span>  Daily Backups</span></li>
                                </ul>
                                <p className="text-start mt-1 mb-1 font-regular">Free Includes</p>
                                <ul className="list-unstyled mt-3 mb-4 text-start">
                                    <li><FontAwesomeIcon icon={faCheckCircle} color={'#83c183'} /><span>  Free DDOS Protection Included</span></li>

                                    <li><FontAwesomeIcon icon={faCheckCircle} color={'#83c183'} /><span>  Free SSL Certificate<i className="cci-question-01 d-flex ms-2" data-bs-trigger="hover focus" data-bs-toggle="popover" data-bs-content="Get your free SSL certificate when choosing this hosting plan" data-bs-original-title="Certificate SSL Free"></i></span></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col border m-2 bg-light box-shadow hvr-grow">
                            <div className="card-header">
                                <span className="h5 my-0 font-weight-normal">Webhosting IV</span>
                                <p className="mt-2 mb-1 text-center">Privacy and security features.</p>
                                <span className="card-title pricing-card-title mb-0 h1">&euro;19,99</span>
                                <small className="text-muted">/ mo</small>
                            </div>
                            <a href="https://my.centnodes.com/products/hosting" className="btn btn-skyblue">Choose Webhosting IV</a>
                            <div className="card-body">
                                <p className="text-start mt-1 mb-1 font-regular">Top Features</p>
                                <ul className="list-unstyled mt-3 mb-4 text-left">
                                    <li><FontAwesomeIcon icon={faCheckCircle} color={'#83c183'} /><span>   Linux Website Hosting.</span></li>
                                    <li><FontAwesomeIcon icon={faCheckCircle} color={'#83c183'} /><span>  100GB of Storage</span></li>
                                    <li><FontAwesomeIcon icon={faCheckCircle} color={'#83c183'} /><span>  Unlimited monthly traffic</span></li>
                                    <li><FontAwesomeIcon icon={faCheckCircle} color={'#83c183'} /><span>  Unlimited Subdomains</span></li>
                                    <li><FontAwesomeIcon icon={faCheckCircle} color={'#83c183'} /><span>  Website Builder</span></li>
                                    <li><FontAwesomeIcon icon={faCheckCircle} color={'#83c183'} /><span>  Free SSL Certificate</span></li>
                                    <li><FontAwesomeIcon icon={faCheckCircle} color={'#83c183'} /><span>  +400 Apps Installer</span></li>
                                    <li><FontAwesomeIcon icon={faCheckCircle} color={'#83c183'} /><span>  Control Panel: Cent OS Web Panel</span></li>
                                    <li><FontAwesomeIcon icon={faCheckCircle} color={'#83c183'} /><span>  Daily Backups</span></li>
                                </ul>
                                <p className="text-start mt-1 mb-1 font-regular">Free Includes</p>
                                <ul className="list-unstyled mt-3 mb-4 text-start">
                                    <li><FontAwesomeIcon icon={faCheckCircle} color={'#83c183'} /><span>  Free DDOS Protection Included</span></li>

                                    <li><FontAwesomeIcon icon={faCheckCircle} color={'#83c183'} /><span>  Free SSL Certificate<i className="cci-question-01 d-flex ms-2" data-bs-trigger="hover focus" data-bs-toggle="popover" data-bs-content="Get your free SSL certificate when choosing this hosting plan" data-bs-original-title="Certificate SSL Free"></i></span></li>
                                </ul>
                            </div>
                        </div>
                    </div>

                </div>

            </section>
            <section className="bg-grey border-bottom border-top p-4  text-center">

                <div className="container mt-3 mb-3">

                    <h3 className="h3">Over 400 scripts available for One-Click Installation</h3>

                    <p className="mt-2">Get started on your website quickly by installing a variety of useful software packages</p>

                    <div className="row justify-content-center features features-transparent">

                        <div className="col-sm rounded p-3 pt-4 m-2 hvr-sink aos-init hvr-hang aos-animate" data-aos="fade-right">

                            <div className="content-img mb-3"><img src={wp_app} alt="Wordpress Logo" /></div>

                            <p className="mt-1">Wordpress is one of the applications available for installation.</p>

                        </div>

                        <div className="col-sm rounded p-3 pt-4 m-2 hvr-sink aos-init hvr-hang aos-animate" data-aos="fade-up">

                            <div className="content-img mb-3"><img src={joomla_app} alt="Joomla" /></div>

                            <p className="mt-1">Joomla is one of the apps available for installation.</p>

                        </div>

                        <div className="col-sm rounded p-3 pt-4 m-2 hvr-sink aos-init hvr-hang aos-animate" data-aos="fade-left">

                            <div className="content-img mb-3"><img src={laravel_app} alt="Laravel" /></div>

                            <p className="mt-1">Laravel is one of the apps available for installation.</p>


                        </div>

                        <div className="w-100"></div>

                        <div className="col-sm rounded p-3 pt-4 m-2 hvr-sink aos-init hvr-hang aos-animate" data-aos="fade-right">

                            <div className="content-img mb-3"><img src={mybb_app} alt="MyBB" /></div>

                            <p className="mt-1">MyBB is one of the apps available for installation.</p>

                        </div>

                        <div className="col-sm rounded p-3 pt-4 m-2 hvr-sink aos-init hvr-hang" data-aos="fade-up">

                            <div className="content-img mb-3"><img src={phpbb_app} alt="phpBB" /></div>

                            <p className="mt-1">phpBB is one of the apps available for installation.</p>

                        </div>

                        <div className="col-sm rounded p-3 pt-4 m-2 hvr-sink aos-init hvr-hang" data-aos="fade-left">

                            <div className="content-img mb-3"><img src={sitepad_app} alt="Sitepad" /></div>

                            <p className="mt-1">Sitepad is one of the apps available for installation.</p>

                        </div>

                        <div className="w-100"></div>

                        <div className="col-sm rounded p-3 pt-4 m-2 hvr-sink aos-init hvr-hang" data-aos="fade-right">


                            <div className="content-img mb-3"><img src={whcms_app} alt="WHCMS" /></div>

                            <p className="mt-1">WHCMS is one of the apps available for installation.</p>


                        </div>

                        <div className="col-sm rounded p-3 pt-4 m-2 hvr-sink aos-init hvr-hang" data-aos="fade-up">

                            <div className="content-img mb-3"><img src={abante_app} alt="AbanteCart" /></div>

                            <p className="mt-1">AbanteCart is one of the apps available for installation.</p>


                        </div>

                        <div className="col-sm rounded p-3 pt-4 m-2 hvr-sink aos-init hvr-hang" data-aos="fade-left">

                            <div className="content-img mb-3"><img src={owncloud_app} alt="OwnCloud" /></div>

                            <p className="mt-1">OwnCloud is one of the apps available for installation.</p>


                        </div>

                    </div>

                </div>

            </section>
            <section className="pt-5 pb-5 bg-light">
                <div className="container pt-3 pb-3 text-center ">
                    <h4 className="h2 lead-title">Why choose Centnodes?</h4>
                    <p className="lead-text">Below we list some advantages of choosing Centnodes for your business</p>
                    <div className="row gy-4 box text-start justify-content-center align-items-center">
                        <div className="col-lg-3">
                            <div className="d-flex flex-column rounded p-3 text-center justify-content-center align-items-center border bg-white">
                                <FontAwesomeIcon icon={faGears} size={'2xl'} className="lgg"></FontAwesomeIcon>
                                <span className="h5 mt-2">Control Panel</span>
                                <span>Our control panel has everything you need to get your website up and running easily.</span>
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="d-flex flex-column rounded p-3 text-center justify-content-center align-items-center border bg-white">
                                <FontAwesomeIcon icon={faFileCircleCheck} size={'2xl'} className="lgg"></FontAwesomeIcon>
                                <span className="h5 mt-2">Free SSL Certificate</span>
                                <span>Get the SSL certificate for free on any Shared Hosting plan with us. Just have a registered domain.</span>
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="d-flex flex-column rounded p-3 text-center justify-content-center align-items-center border bg-white">
                                <FontAwesomeIcon icon={faMousePointer} size={'2xl'} className="lgg"></FontAwesomeIcon>
                                <span className="h5 mt-2">1 Click Installer</span>
                                <span>Choose from a variety of pre-built apps and install with one click from the control panel.</span>
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="d-flex flex-column rounded p-3 text-center justify-content-center align-items-center border bg-white">
                                <FontAwesomeIcon icon={faFolder} size={'2xl'} className="lgg"></FontAwesomeIcon>
                                <span className="h5 mt-2">File Manager</span>
                                <span>With the CWP control panel you can manage your files through the panel or FTP.</span>
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="d-flex flex-column rounded p-3 text-center justify-content-center align-items-center border bg-white">
                                <FontAwesomeIcon icon={faHardDrive} size={'2xl'} className="lgg"></FontAwesomeIcon>
                                <span className="h5 mt-2">SSD Storage</span>
                                <span>All of our hosting packages feature SSD storage technology for faster website loading.</span>
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="d-flex flex-column rounded p-3 text-center justify-content-center align-items-center border bg-white">
                                <FontAwesomeIcon icon={faChartArea} size={'2xl'} className="lgg"></FontAwesomeIcon>
                                <span className="h5 mt-2">Website Stats</span>
                                <span>See all your website statistics such as bandwidth limit, disk, ram, users who accessed and more.</span>
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="d-flex flex-column rounded p-3 text-center justify-content-center align-items-center border bg-white">
                                <FontAwesomeIcon icon={faShield} size={'2xl'} className="lgg"></FontAwesomeIcon>
                                <span className="h5 mt-2">DDoS Protection</span>
                                <span>All of our shared hosting servers have state-of-the-art protection against DDOS attacks.</span>
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="d-flex flex-column rounded p-3 text-center justify-content-center align-items-center border bg-white">
                                <FontAwesomeIcon icon={faHeadset} size={'2xl'} className="lgg"></FontAwesomeIcon>
                                <span className="h5 mt-2">24/7/365 support</span>
                                <span>We're here to help you with whatever you need when you're ready to host your website.</span>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section aria-label="Common questions" className=" border-top accordion-bottom text-start p-4 pt-5 pb-5">

                <div className="container">

                    <div className="row">

                        <div className="col-sm text-left mt-3 mb-3">

                            <span className="h1">Common questions</span>

                            <p className="mt-4">To help you make an informed decision, we've compiled a list of our frequently asked questions related to the services listed on this page.</p>

                            <p>If you cannot find the answer to your question or would like more information on the matter, you can visit our complete Knowledgebase or contact us at any time and we will be happy to assist you.</p>

                            <div className="btn-groups">

                                <a href="/knowledgebase" className="btn btn-primary">Knowledgebase</a>
                                <a href="/help" className="btn btn-outline-transparent">Contact Us</a>

                            </div>

                        </div>

                        <div className="col-sm text-left mt-3 mb-3">

                            <span className="h2">Do you have any doubts?</span>

                            <p className="mt-3">See if your question has already been answered in the list below</p>

                            <div className="accordion accordion-flush" id="accordionQuestion">
                                <div className="accordion-item border mt-1 mb-1">
                                    <h2 className="accordion-header" id="flush-headingOne">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">What is Shared Hosting?</button>
                                    </h2>
                                    <div id="flush-collapseOne" className="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionQuestion">
                                        <div className="accordion-body">Shared hosting, also referred to as virtual hosting, is a way to host your website on a single physical server that also hosts additional sites. Within the server, there are software applications that make managing and accessing your website easy.</div>
                                    </div>
                                </div>
                                <div className="accordion-item border mt-1 mb-1">
                                    <h2 className="accordion-header" id="flush-headingTwo">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">What is a domain name?</button>
                                    </h2>
                                    <div id="flush-collapseTwo" className="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionQuestion">
                                        <div className="accordion-body">A domain name refers to your website address. This is what users type in a browser's search bar to directly access your website. A domain name is unique and cannot be shared between different sites. For example: centnodes.com.</div>
                                    </div>
                                </div>
                                <div className="accordion-item border mt-1 mb-1">
                                    <h2 className="accordion-header" id="flush-headingThree">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">What is a VPS server and why should I choose it?</button>
                                    </h2>
                                    <div id="flush-collapseThree" className="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionQuestion">
                                    <div className="accordion-body">A VPS (Virtual Private Server) is a virtualized server providing dedicated resources within a shared physical server. It's a cost-effective solution offering more control than shared hosting, making it suitable for websites or applications with moderate resource requirements, providing a balance between affordability and performance.</div>
                                    </div>
                                </div>
                                <div className="accordion-item border mt-1 mb-1">
                                    <h2 className="accordion-header" id="flush-headingFour">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFour" aria-expanded="false" aria-controls="flush-collapseFour">What is Dedicated Server?</button>
                                    </h2>
                                    <div id="flush-collapseFour" className="accordion-collapse collapse" aria-labelledby="flush-headingFour" data-bs-parent="#accordionQuestion">
                                    <div className="accordion-body">A dedicated server is a high-performance computer exclusively dedicated to a single user or organization. It offers enhanced security, customization, and control, making it ideal for resource-intensive applications and websites with high traffic.</div>
                                    </div>
                                </div>
                                <div className="accordion-item border mt-1 mb-1">
                                    <h2 className="accordion-header" id="flush-headingFive">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFive" aria-expanded="false" aria-controls="flush-collapseFive">How long does it take to release the order?</button>
                                    </h2>
                                    <div id="flush-collapseFive" className="accordion-collapse collapse" aria-labelledby="flush-headingFive" data-bs-parent="#accordionQuestion">
                                        <div className="accordion-body">Our hosting services have immediate release as soon as the payment is cleared and confirmed by our system. Service access information is automatically sent to the registered email.</div>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>

                </div>

            </section>
            <Footer />
            <div className="botomButtons d-none" id={'backToTopButton'}>
                <div className="row p-0 m-0">
                    <div className="col-2 backToTop justify-content-start">
                        <FontAwesomeIcon className="backToTopIcon" onClick={() => window.scrollTo(0, 0)} icon={faArrowUp} />
                    </div>
                </div>
            </div>
        </div >
    )
}



