import { faArrowUp, faCircleQuestion, faComment, faEnvelope, faFire, faTicket } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import { Form } from 'react-bootstrap'
import Footer from '../../components/Footer/Footer'
import { SiteNavigation } from '../../components/Navigation/SiteNavigation'
import './Help.css'
import contactImage from '../../images/design/support_header.svg'
import { Helmet } from 'react-helmet-async'
import apiService from '../../services/apiService'

export const Help = () => {

    const [knowledgeBaseItems, setKnowledgebaseItems] = useState();

    const getKnowledge = async () => {
        let items = await apiService.getKnowledgebaseItems();


        setKnowledgebaseItems(items)
    }

    const slugify = str =>
        str
            .toLowerCase()
            .trim()
            .replace(/[^\w\s-]/g, '')
            .replace(/[\s_-]+/g, '-')
            .replace(/^-+|-+$/g, '');

    const goToItem = async (item) => {
        window.location.href = '/knowledgebase/' + slugify(item.title);
    }

    const searchKnowledgebase = (e) => {
        e.preventDefault()

        return false;
    }

    useEffect(() => {
        getKnowledge()
    }, [])
    return (
        <div>
            <Helmet>
                <title>Centnodes | Support</title>
                <meta
                    name="description"
                    content="Our whole team of skilled and enthusiastics are ready to assist you,
                whether it's password resets or setting up your insance with some asstiance. Use any method on this page to get in touch with us."
                />;
            </Helmet>
            <SiteNavigation />
            <div className="border-bottom py-4">
                <div className="container ">
                    <div className="row align-items-center mt-5 ">
                        <div className="col-md-5 d-flex flex-column">
                            <span className="h5">Support</span>
                            <span className="h2 mt-2">Need help? We're here for you!</span>
                            <p className="mt-2">Our whole team of skilled and enthusiastics are ready to assist you,
                                whether it's password resets or setting up your insance with some asstiance. Use any method on this page to get in touch with us.</p>
                        </div>
                        <div className="col-md-7 d-flex justify-content-center align-items-center mt-5 mt-xl-0">
                            <div className="content-img"><img src={contactImage} alt="server" /></div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="border-bottom bg-white py-4">
                <div className="container ">
                    <div className="row mt-5 make-equal">
                        <h2 className='text-center p-3'>Overview of Support</h2>

                        <div onClick={() => window.location = 'mailto:support@centnodes.com'} className="col-md-4 col-sm-12">
                            <div className='col border rounded bg-light p-4 d-flex justify-content-center align-items-center flex-column m-2  aos-init' data-aos="fade-down-right">
                                <h4 className="h4">Email</h4>
                                {/* <Badge>Response within 24 hours.</Badge> */}
                                <p className="mt-2">Reach out to us via email, our team is ready for any question.</p>
                                <FontAwesomeIcon color={'#2f57a4'} size={'xl'} icon={faEnvelope} />
                            </div>
                        </div>
                        <div onClick={() => window.location.href = 'https://my.centnodes.com/tickets'} className="col-md-4 col-sm-12">
                            <div className='col border rounded bg-light p-4 d-flex justify-content-center align-items-center flex-column m-2  aos-init' data-aos="fade-up-left">
                                <h4 className="h4">Support Ticket</h4>
                                {/* <Badge>Response within 24 hours.</Badge> */}
                                <p className="mt-2">Reach out to us via a support Ticket, our team will pickup your ticket.</p>
                                <FontAwesomeIcon color={'#2f57a4'} size={'xl'} icon={faTicket} />
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-12">
                            <div className='col border rounded bg-light p-4 d-flex justify-content-center align-items-center flex-column m-2  aos-init' data-aos="fade-down-left">
                                <h4 className="h4">Live chat</h4>
                                {/* <Badge>Response within 5 minutes.</Badge> */}
                                <p className="mt-2">Reach out to us via livechat and talk to one of our team members.</p>
                                <FontAwesomeIcon color={'#2f57a4'} size={'xl'} icon={faComment} />
                            </div>
                        </div>
                    </div>
                    <div className="row mt-5">
                        <h3 className="h3 mb-3">Search our Knowledgebase</h3>
                        <div className="col-md-12">
                            <div className="searchFAQ">
                                <Form>
                                    <Form.Group>
                                        <div className="row d-flex justify-content-center">
                                            <div className="col-9 col-md-10 col-lg-10">
                                                <Form.Control
                                                    type="text"
                                                    id="faqSearch"
                                                    placeholder="Search for example: How to recover a backup for my VPS."
                                                    onChange={(e) => searchKnowledgebase(e)}

                                                />
                                            </div>
                                            <div className="col-3 col-md-2 col-lg-2 p-0">
                                                <button className=" btn btn-centnodes btn-lg">Search</button>
                                            </div>
                                        </div>
                                    </Form.Group>
                                </Form>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-5 gap-2 gap-md-0 gap-lg-0">
                        <div className="col-md-4">
                            <div className="col border rounded bg-light p-4 d-flex  flex-column m-2">
                                <h4 className="fw-bold h4">Latest: </h4>
                                <hr />
                                <ul className='list-unstyled'>
                                    {knowledgeBaseItems && knowledgeBaseItems.filter(knowLedgeBaseItem => knowLedgeBaseItem.category === "backups").length > 0 ? knowledgeBaseItems.filter(knowLedgeBaseItem => knowLedgeBaseItem.category === "backups").map(filteredItem => (
                                        <li onClick={() => goToItem(filteredItem)} className="m-2 list-item faqListItem"><FontAwesomeIcon icon={faCircleQuestion} size={'lg'} color={'#2f57a4'} /> {filteredItem.title}</li>
                                    )) : <li className="list-item">No items yet.</li>}
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="col border rounded bg-light p-4 d-flex  flex-column m-2">
                                <h4 className="fw-bold h4">Popular <FontAwesomeIcon icon={faFire} color={'#2f57a4'} />: </h4>
                                <hr />
                                <ul className='list-unstyled'>
                                    {knowledgeBaseItems && knowledgeBaseItems.filter(knowLedgeBaseItem => knowLedgeBaseItem.category === "servers").length > 0 ? knowledgeBaseItems.filter(knowLedgeBaseItem => knowLedgeBaseItem.category === "servers").map(filteredItem => (
                                        <li onClick={() => goToItem(filteredItem)} className="m-2 list-item faqListItem"><FontAwesomeIcon icon={faCircleQuestion} size={'lg'} color={'#2f57a4'} /> {filteredItem.title}</li>
                                    )) : <li className="list-item">No items yet.</li>}
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="col border rounded bg-light p-4 d-flex  flex-column m-2">
                                <h4 className="fw-bold h4">FAQ: </h4>
                                <hr />
                                <ul className='list-unstyled'>
                                    {knowledgeBaseItems && knowledgeBaseItems.filter(knowLedgeBaseItem => knowLedgeBaseItem.categoryid == 2).length > 0 ? knowledgeBaseItems.filter(knowLedgeBaseItem => knowLedgeBaseItem.categoryid == 2).map(filteredItem => (
                                        <li onClick={() => goToItem(filteredItem)} className="m-2 list-item faqListItem"><FontAwesomeIcon icon={faCircleQuestion} size={'lg'} color={'#2f57a4'} /> {filteredItem.title}</li>
                                    )) : <li className="list-item">No items yet.</li>}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
            <div className="botomButtons d-none" id={'backToTopButton'}>
                <div className="row p-0 m-0">
                    <div className="col-2 backToTop justify-content-start">
                        <FontAwesomeIcon className="backToTopIcon" onClick={() => window.scrollTo(0, 0)} icon={faArrowUp} />
                    </div>
                </div>
            </div>
        </div>
    )
}
