import { faBoltLightning, faCheckCircle, faDesktopAlt, faFlag, faGear, faHeadset, faShoppingBasket, faUpload } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect } from 'react'
import Footer from '../../../components/Footer/Footer'
import { SiteNavigation } from '../../../components/Navigation/SiteNavigation'
import './GamingServices.css'
import serverHeaderImage from "../../../images/design/minecraft-server-header.svg";
import discordLogo from "../../../images/design/discord-logo.png";

import { Helmet } from 'react-helmet-async'

export const DiscordServers = () => {

    useEffect(() => {
    })
    return (
        <div>
            <Helmet>
                <title>Centnodes | Discord Servers</title>
                <meta
                    name="description"
                    content="Launch a Discord server in less than 5 minutes."
                />;
            </Helmet>
            <SiteNavigation />
            <div className="border-bottom">
                <div className="container pb-3">
                    <div className="row align-items-center mt-5 ">
                        <div className="col-md-5 d-flex flex-column">
                            <span className="h5">Discord Servers</span>
                            <span className="h2 mt-2">Community-centric. Interactive. Engaging.</span>
                            <p className="mt-2">Step into the vibrant world of Discord with confidence, powered by Centnodes' Hosting. Our servers ensure seamless communication, collaboration, and connection for your community. Thrive with us in the boundless realm of Discord.</p>
                            <div className="btn-group">
                                <a href="#packages" className="btn btn-primary rounded m-1">See packages</a>
                                <a href="/help" className="btn btn-outline-primary rounded m-1">Contact Us</a>
                            </div>
                        </div>
                        <div className="col-md-7 d-flex justify-content-center align-items-center mt-5 mt-xl-0">
                            <div className="content-img"><img src={serverHeaderImage} alt="server" /></div>
                        </div>
                    </div>
                </div>
            </div>
            <section className=" bg-light p-4 text-center" id="plans">
                <div className="container d-flex mt-5  flex-column align-items-center">
                    <h2 className="h2">Discord Servers</h2>
                    <p>Choose an ideal plan for your game server and put your work online today</p>
                </div>
                <div className="products-package py-5 mt-5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-4">
                                <div className="card mb-5 border">
                                    <div id="packages" className="card-body text-center">
                                        <img className="img-fluid mb-4" src={discordLogo} width="80" />
                                        <h4 className="mb-3">Discord | 64MB</h4>
                                        <ul className="mb-4 mt-2">
                                            <li><FontAwesomeIcon color={'#51bbb5'} icon={faCheckCircle} />  500 MB SSD</li>
                                            <li><FontAwesomeIcon color={'#51bbb5'} icon={faCheckCircle} />  64 MB RAM</li>
                                            <li><FontAwesomeIcon color={'#51bbb5'} icon={faCheckCircle} />  1 Backup</li>
                                        </ul>
                                        <h2 className="mb-4 h2">&euro; 0.65 <span className="month">/ mo</span></h2>
                                        <a href="https://my.centnodes.com/products/gaming" className="btn btn-primary"><FontAwesomeIcon icon={faShoppingBasket} /> Order now</a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="card mb-5 border">
                                    <div id="packages" className="card-body text-center">
                                        <img className="img-fluid mb-4" src={discordLogo} width="80" />
                                        <h4 className="mb-3">Discord | 128MB</h4>
                                        <ul className="mb-4 mt-2">
                                            <li><FontAwesomeIcon color={'#51bbb5'} icon={faCheckCircle} />  1 GB SSD</li>
                                            <li><FontAwesomeIcon color={'#51bbb5'} icon={faCheckCircle} />  128 MB RAM</li>
                                            <li><FontAwesomeIcon color={'#51bbb5'} icon={faCheckCircle} />  1 Backup</li>
                                            <li><FontAwesomeIcon color={'#51bbb5'} icon={faCheckCircle} />  1 MYSQL Database</li>
                                        </ul>
                                        <h2 className="mb-4 h2">&euro; 0.99 <span className="month">/ mo</span></h2>
                                        <a href="https://my.centnodes.com/products/gaming" className="btn btn-primary"><FontAwesomeIcon icon={faShoppingBasket} /> Order now</a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="card mb-5 border">
                                    <div id="packages" className="card-body text-center">
                                        <img className="img-fluid mb-4" src={discordLogo} width="80" />
                                        <h4 className="mb-3">Discord | 256MB</h4>
                                        <ul className="mb-4 mt-2">
                                            <li><FontAwesomeIcon color={'#51bbb5'} icon={faCheckCircle} />  2 GB SSD</li>
                                            <li><FontAwesomeIcon color={'#51bbb5'} icon={faCheckCircle} />  256 MB RAM</li>
                                            <li><FontAwesomeIcon color={'#51bbb5'} icon={faCheckCircle} />  1 Backup</li>
                                            <li><FontAwesomeIcon color={'#51bbb5'} icon={faCheckCircle} />  1 MYSQL Database</li>
                                        </ul>
                                        <h2 className="mb-4 h2">&euro; 1.29 <span className="month">/ mo</span></h2>
                                        <a href="https://my.centnodes.com/products/gaming" className="btn btn-primary"><FontAwesomeIcon icon={faShoppingBasket} /> Order now</a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="card mb-5 border">
                                    <div id="packages" className="card-body text-center">
                                        <img className="img-fluid mb-4" src={discordLogo} width="80" />
                                        <h4 className="mb-3">Discord | 512MB</h4>
                                        <ul className="mb-4 mt-2">
                                            <li><FontAwesomeIcon color={'#51bbb5'} icon={faCheckCircle} />  3GB SSD</li>
                                            <li><FontAwesomeIcon color={'#51bbb5'} icon={faCheckCircle} />  512 MB RAM</li>
                                            <li><FontAwesomeIcon color={'#51bbb5'} icon={faCheckCircle} />  1 Backup</li>
                                            <li><FontAwesomeIcon color={'#51bbb5'} icon={faCheckCircle} />  1 MYSQL Database</li>
                                        </ul>
                                        <h2 className="mb-4 h2">&euro; 2.25 <span className="month">/ mo</span></h2>
                                        <a href="https://my.centnodes.com/products/gaming" className="btn btn-primary"><FontAwesomeIcon icon={faShoppingBasket} /> Order now</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="bg-white border-top">
                <div className="container ">
                    <h4 className="h2 lead-title text-center">Why choose Centnodes?</h4>
                    <p className="lead-text text-center">Below we list some advantages of choosing Centnodes for your gaming server</p>
                    <div className="row gy-4 box text-start justify-content-center align-items-center">
                        <div className="col-lg-4">
                            <div className="d-flex flex-column rounded p-3 text-center justify-content-center align-items-center border bg-light">
                                <FontAwesomeIcon icon={faBoltLightning} size={'2xl'} className="lgg"></FontAwesomeIcon>
                                <span className="h5 mt-2">Performance</span>
                                <span>Experience seamless voice and streaming with our high-performance discord servers, eliminating lag and downtime.</span>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="d-flex flex-column rounded p-3 text-center justify-content-center align-items-center border bg-light">
                                <FontAwesomeIcon icon={faGear} size={'2xl'} className="lgg"></FontAwesomeIcon>
                                <span className="h5 mt-2">Scalability</span>
                                <span>Whether you're a solo builder or running a massive multiplayer server, our scalable solutions meet your needs.</span>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="d-flex flex-column rounded p-3 text-center justify-content-center align-items-center border bg-light">
                                <FontAwesomeIcon icon={faDesktopAlt} size={'2xl'} className="lgg"></FontAwesomeIcon>
                                <span className="h5 mt-2">Control</span>
                                <span>Enjoy full control over your discord bot server with our easy-to-use control panel.</span>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="d-flex flex-column rounded p-3 text-center justify-content-center align-items-center border bg-light">
                                <FontAwesomeIcon icon={faUpload} size={'2xl'} className="lgg"></FontAwesomeIcon>
                                <span className="h5 mt-2">Uptime</span>
                                <span>Our reliable servers ensure your discord servers are accessible 24/7.</span>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="d-flex flex-column rounded p-3 text-center justify-content-center align-items-center border bg-light">
                                <FontAwesomeIcon icon={faHeadset} size={'2xl'} className="lgg"></FontAwesomeIcon>
                                <span className="h5 mt-2">Support</span>
                                <span>Get instant help when you need it from our dedicated support team.</span>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="d-flex flex-column rounded p-3 text-center justify-content-center align-items-center border bg-light">
                                <FontAwesomeIcon icon={faFlag} size={'2xl'} className="lgg"></FontAwesomeIcon>
                                <span className="h5 mt-2">Freedom</span>
                                <span>Create, explore, and survive without limits in your discord servers hosted by Centnodes.</span>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
            <section aria-label="Common questions" className="pt-5 border-bottom">
                <div className="container">
                    <div className="row g-4">
                        <div className="col-md-6">
                            <div className="text-left me-5">
                                <span className="h2">Common questions</span>
                                <p className="mt-4">To help you make an informed decision, we've compiled a list of our frequently asked questions related to the services listed on this page.</p>
                                <p>If you cannot find the answer to your question or would like more information on the matter, you can visit our complete Knowledgebase or contact us at any time and we will be happy to assist you.</p>
                                <div className="btn-groups">
                                    <a href="/knowledgebase" className="btn btn-primary mt-1 mb-1">Knowledgebase</a>
                                    <a href="/help" className="btn btn-outline-transparent mt-1 mb-1">Contact Us</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="text-left">
                                <span className="h3">Do you have any doubts?</span>
                                <p className="mt-3">See if your question has already been answered in the list below</p>
                                <div className="accordion accordion-flush" id="accordionQuestion">
                                    <div className="accordion-item border mt-1 mb-1">
                                        <h2 className="accordion-header" id="flush-headingThree">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">How resilient are your discord servers?</button>
                                        </h2>
                                        <div id="flush-collapseThree" className="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionQuestion">
                                            <div className="accordion-body">Our discord servers are robust and designed to handle the demanding environment of the discord servers..</div>
                                        </div>
                                    </div>
                                    <div className="accordion-item border mt-1 mb-1">
                                        <h2 className="accordion-header" id="flush-headingTwo">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">Can your servers handle my growing needs?</button>
                                        </h2>
                                        <div id="flush-collapseTwo" className="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionQuestion">
                                            <div className="accordion-body">Yes, our servers are designed to scale and adapt to your growing needs, whether you're just starting or leading a large clan.</div>
                                        </div>
                                    </div>

                                    <div className="accordion-item border mt-1 mb-1">
                                        <h2 className="accordion-header" id="flush-headingFour">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFour" aria-expanded="false" aria-controls="flush-collapseFour">How much control do I have over my discord server?</button>
                                        </h2>
                                        <div id="flush-collapseFour" className="accordion-collapse collapse" aria-labelledby="flush-headingFour" data-bs-parent="#accordionQuestion">
                                            <div className="accordion-body">Our control panel gives you complete control over your discord server, allowing you to manage every aspect of your game.</div>
                                        </div>
                                    </div>
                                    <div className="accordion-item border mt-1 mb-1">
                                        <h2 className="accordion-header" id="flush-headingFive">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFive" aria-expanded="false" aria-controls="flush-collapseFive">What is your server uptime?</button>
                                        </h2>
                                        <div id="flush-collapseFive" className="accordion-collapse collapse" aria-labelledby="flush-headingFive" data-bs-parent="#accordionQuestion">
                                            <div className="accordion-body">Our servers offer excellent uptime, ensuring your  discord server is accessible 24/7</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    )
}