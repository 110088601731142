import { faArrowUp, faCamera, faCancel, faCheckCircle, faClock, faCloudArrowUp, faCopy, faHardDrive, faLock, faMailBulk } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useState } from 'react'
import Footer from '../../components/Footer/Footer'
import { SiteNavigation } from '../../components/Navigation/SiteNavigation'
import './ServerServices.css'
import serverPlanImage from '../../images/design/freedom_home.svg'
import deployDesktopimage from '../../images/deployserver_desktop.png'
import deployMobileImage from '../../images/deployserver_phone.png'
import deployTabletimage from '../../images/deployserver_tablet.png'
import serverHeaderImage from '../../images/design/server_services.svg'
import imageDelivery from '../../images/design/instant_delivery_image.svg'
import { Form, Tab, Tabs } from 'react-bootstrap'
import { Helmet } from 'react-helmet-async'

export const ServerServices = () => {

    const [showWindowsPlan, setShowWindowsPlan] = useState(false);
    return (
        <div>
            <Helmet>
                <title>Centnodes | Virtual Private Servers</title>
                <meta
                    name="description"
                    content="Get high performance without the high costs. There is a Centnodes Server available for every need – and now, with 3x more traffic included by default, there's never been a better time to buy."
                />;
            </Helmet>
            <SiteNavigation />
            <div className="border-bottom py-4">
                <div className="container ">
                    <div className="row align-items-center mt-5 ">
                        <div className="col-md-5 d-flex flex-column">
                            <span className="h5">VPS Hosting</span>
                            <span className="h2 mt-2">Top notch servers to boost your business.</span>
                            <p className="mt-2">Get high performance without the high costs. There is a Centnodes Server available for every need – and now, with 3x more traffic included by default, there's never been a better time to buy.</p>
                            <div className="btn-group">
                                <a href="#packages" className="btn btn-primary rounded m-1">See packages</a>
                                <a href="/help" className="btn btn-outline-primary rounded m-1">Contact Us</a>
                            </div>
                        </div>
                        <div className="col-md-7 d-flex justify-content-center align-items-center mt-5 mt-xl-0">
                            <div className="content-img"><img alt='server image' src={serverHeaderImage} /></div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="border-bottom bg-white py-4">
                <div className="container  mt-5">
                    <div className="row mt-5 text-center">
                        <h1 className="h2">Choose the VPS that suits you, from Starter to Expert</h1>
                    </div>
                    <div className='row m-0 g-2 mt-5'>
                        <div className="hostingPlanSwitch mt-3 mb-2 text-center">
                            <h4 className="h4">VPS<Form.Check className='d-inline mx-3' type="switch" reverse={'true'} onChange={() => setShowWindowsPlan(!showWindowsPlan)} /> VPS Windows</h4>
                        </div>

                        {showWindowsPlan ?
                            (
                                <>
                                    <div className="col-md-3 col-lg-3 col-6">
                                        <div className='hostingPackage p-4 bg-white'>
                                            <div id="packages" className="hostingPackageTitle p-0 border">
                                                <h5>Windows Cloud I</h5>
                                            </div>

                                            <h6 className="border-top pt-2 ">Specifications</h6>

                                            <div className="planSpecifications mt-3">
                                                <p><FontAwesomeIcon color={'#51bbb5'} icon={faCheckCircle} /> <span className='fw-bold'>1 Core</span> CPU</p>
                                                <p><FontAwesomeIcon color={'#51bbb5'} icon={faCheckCircle} /> <span className='fw-bold'>2 GB</span> RAM</p>
                                                <p><FontAwesomeIcon color={'#51bbb5'} icon={faCheckCircle} /> <span className='fw-bold'>55 GB</span> SSD Storage</p>
                                                <p><FontAwesomeIcon color={'#51bbb5'} icon={faCheckCircle} /> <span className='fw-bold'>2 TB</span> Bandwith</p>
                                            </div>
                                            <h5 className='text-center'>&euro;5,99 /mo</h5>

                                            <div className="mt-4 d-flex justify-content-center">
                                                <a href={'https://my.centnodes.com/products/login'} className='btn btn-centnodes'>Order {'>'}</a>
                                            </div>

                                        </div>

                                    </div>
                                    <div className="col-md-3 col-lg-3 col-6">
                                        <div className='hostingPackage p-4 bg-white'>
                                            <div className="hostingPackageTitle p-0 border">
                                                <h5>Windows Cloud II</h5>
                                            </div>

                                            <h6 className="border-top pt-2 ">Specifications</h6>

                                            <div className="planSpecifications mt-3">
                                                <p><FontAwesomeIcon color={'#51bbb5'} icon={faCheckCircle} /> <span className='fw-bold'>2 Core</span> CPU</p>
                                                <p><FontAwesomeIcon color={'#51bbb5'} icon={faCheckCircle} /> <span className='fw-bold'>2 GB</span> RAM</p>
                                                <p><FontAwesomeIcon color={'#51bbb5'} icon={faCheckCircle} /> <span className='fw-bold'>69 GB</span> SSD Storage</p>
                                                <p><FontAwesomeIcon color={'#51bbb5'} icon={faCheckCircle} /> <span className='fw-bold'>4 TB</span> Bandwith</p>
                                            </div>
                                            <h5 className='text-center'>&euro;24,99 /mo</h5>

                                            <div className="mt-4 d-flex justify-content-center">
                                                <a href={'https://my.centnodes.com/products/login'} className='btn btn-centnodes'>Order {'>'}</a>

                                            </div>

                                        </div>

                                    </div>
                                    <div className="col-md-3 col-lg-3 col-6">
                                        <div className='hostingPackage p-4 bg-white'>
                                            <div className="hostingPackageTitle p-0 border">
                                                <h5>Windows Cloud III</h5>
                                            </div>

                                            <h6 className="border-top pt-2 ">Specifications</h6>

                                            <div className="planSpecifications mt-3">
                                                <p><FontAwesomeIcon color={'#51bbb5'} icon={faCheckCircle} /> <span className='fw-bold'>4 Core</span> CPU</p>
                                                <p><FontAwesomeIcon color={'#51bbb5'} icon={faCheckCircle} /> <span className='fw-bold'>8 GB</span> RAM</p>
                                                <p><FontAwesomeIcon color={'#51bbb5'} icon={faCheckCircle} /> <span className='fw-bold'>100 GB</span> SSD Storage</p>
                                                <p><FontAwesomeIcon color={'#51bbb5'} icon={faCheckCircle} /> <span className='fw-bold'>4 TB</span> Bandwith</p>
                                            </div>
                                            <h5 className='text-center'>&euro;99,99 /mo</h5>

                                            <div className="mt-4 d-flex justify-content-center">
                                                <a href={'https://my.centnodes.com/products/login'} className='btn btn-centnodes'>Order {'>'}</a>

                                            </div>

                                        </div>

                                    </div>
                                    <div className="col-md-3 col-lg-3 col-6">
                                        <div className='hostingPackage p-4 bg-white'>
                                            <div className="hostingPackageTitle p-0 border">
                                                <h5>Windows Cloud IV</h5>
                                            </div>

                                            <h6 className="border-top pt-2 ">Specifications</h6>

                                            <div className="planSpecifications mt-3">
                                                <p><FontAwesomeIcon color={'#51bbb5'} icon={faCheckCircle} /> <span className='fw-bold'>6 Core</span> CPU</p>
                                                <p><FontAwesomeIcon color={'#51bbb5'} icon={faCheckCircle} /> <span className='fw-bold'>16 GB</span> RAM</p>
                                                <p><FontAwesomeIcon color={'#51bbb5'} icon={faCheckCircle} /> <span className='fw-bold'>320 GB</span> SSD Storage</p>
                                                <p><FontAwesomeIcon color={'#51bbb5'} icon={faCheckCircle} /> <span className='fw-bold'>6 TB</span> Bandwith</p>
                                            </div>
                                            <h5 className='text-center'>&euro;167,99 /mo</h5>

                                            <div className="mt-4 d-flex justify-content-center">
                                                <a href={'https://my.centnodes.com/products/login'} className='btn btn-centnodes'>Order {'>'}</a>

                                            </div>

                                        </div>

                                    </div>
                                </>
                            ) :
                            (
                                <>
                                    <div className="col-md-3 col-lg-3 col-6">
                                        <div className='hostingPackage p-4 bg-white'>
                                            <div className="hostingPackageTitle p-0 border">
                                                <h5>Cloud I</h5>
                                            </div>

                                            <h6 className="border-top pt-2 ">Specifications</h6>

                                            <div className="planSpecifications mt-3">
                                                <p><FontAwesomeIcon color={'#51bbb5'} icon={faCheckCircle} /> <span className='fw-bold'>1 Core</span> CPU</p>
                                                <p><FontAwesomeIcon color={'#51bbb5'} icon={faCheckCircle} /> <span className='fw-bold'>1 GB</span> RAM</p>
                                                <p><FontAwesomeIcon color={'#51bbb5'} icon={faCheckCircle} /> <span className='fw-bold'>25 GB</span> SSD Storage</p>
                                                <p><FontAwesomeIcon color={'#51bbb5'} icon={faCheckCircle} /> <span className='fw-bold'>1 TB</span> Bandwith</p>
                                            </div>
                                            <h5 className='text-center'>&euro;4,99 /mo</h5>

                                            <div className="mt-4 d-flex justify-content-center">
                                                <a href={'https://my.centnodes.com/products/login'} className='btn btn-centnodes'>Order {'>'}</a>

                                            </div>

                                        </div>

                                    </div>
                                    <div className="col-md-3 col-lg-3 col-6">
                                        <div className='hostingPackage p-4 bg-white'>
                                            <div className="hostingPackageTitle p-0 border">
                                                <h5>Cloud II</h5>
                                            </div>

                                            <h6 className="border-top pt-2 ">Specifications</h6>

                                            <div className="planSpecifications mt-3">
                                                <p><FontAwesomeIcon color={'#51bbb5'} icon={faCheckCircle} /> <span className='fw-bold'>1 Core</span> CPU</p>
                                                <p><FontAwesomeIcon color={'#51bbb5'} icon={faCheckCircle} /> <span className='fw-bold'>2 GB</span> RAM</p>
                                                <p><FontAwesomeIcon color={'#51bbb5'} icon={faCheckCircle} /> <span className='fw-bold'>55 GB</span> SSD Storage</p>
                                                <p><FontAwesomeIcon color={'#51bbb5'} icon={faCheckCircle} /> <span className='fw-bold'>2 TB</span> Bandwith</p>
                                            </div>
                                            <h5 className='text-center'>&euro;10,99 /mo</h5>

                                            <div className="mt-4 d-flex justify-content-center">
                                                <a href={'https://my.centnodes.com/products/login'} className='btn btn-centnodes'>Order {'>'}</a>

                                            </div>

                                        </div>

                                    </div>
                                    <div className="col-md-3 col-lg-3 col-6">
                                        <div className='hostingPackage p-4 bg-white'>
                                            <div className="hostingPackageTitle p-0 border">
                                                <h5>Cloud III</h5>
                                            </div>

                                            <h6 className="border-top pt-2 ">Specifications</h6>

                                            <div className="planSpecifications mt-3">
                                                <p><FontAwesomeIcon color={'#51bbb5'} icon={faCheckCircle} /> <span className='fw-bold'>2 Core</span> CPU</p>
                                                <p><FontAwesomeIcon color={'#51bbb5'} icon={faCheckCircle} /> <span className='fw-bold'>4 GB</span> RAM</p>
                                                <p><FontAwesomeIcon color={'#51bbb5'} icon={faCheckCircle} /> <span className='fw-bold'>80 GB</span> SSD Storage</p>
                                                <p><FontAwesomeIcon color={'#51bbb5'} icon={faCheckCircle} /> <span className='fw-bold'>3 TB</span> Bandwith</p>
                                            </div>
                                            <h5 className='text-center'>&euro;21,99 /mo</h5>

                                            <div className="mt-4 d-flex justify-content-center">
                                                <a href={'https://my.centnodes.com/products/login'} className='btn btn-centnodes'>Order {'>'}</a>

                                            </div>

                                        </div>

                                    </div>
                                    <div className="col-md-3 col-lg-3 col-6">
                                        <div className='hostingPackage p-4 bg-white'>
                                            <div className="hostingPackageTitle p-0 border">
                                                <h5>Cloud IV</h5>
                                            </div>

                                            <h6 className="border-top pt-2 ">Specifications</h6>

                                            <div className="planSpecifications mt-3">
                                                <p><FontAwesomeIcon color={'#51bbb5'} icon={faCheckCircle} /> <span className='fw-bold'>4 Core</span> CPU</p>
                                                <p><FontAwesomeIcon color={'#51bbb5'} icon={faCheckCircle} /> <span className='fw-bold'>8 GB</span> RAM</p>
                                                <p><FontAwesomeIcon color={'#51bbb5'} icon={faCheckCircle} /> <span className='fw-bold'>160 GB</span> SSD Storage</p>
                                                <p><FontAwesomeIcon color={'#51bbb5'} icon={faCheckCircle} /> <span className='fw-bold'>4 TB</span> Bandwith</p>
                                            </div>
                                            <h5 className='text-center'>&euro;42,49 /mo</h5>

                                            <div className="mt-4 d-flex justify-content-center">
                                                <a href={'https://my.centnodes.com/products/login'} className='btn btn-centnodes'>Order {'>'}</a>

                                            </div>

                                        </div>

                                    </div>
                                </>
                            )}
                    </div>
                </div>
                <div class="bg-light border-top border-bottom pt-5 pb-5 mt-3">
                    <div class="container text-center pt-2 pb-2">

                        <h4 class="h2 lead-title">See more about the technical specifications of our plans</h4>

                        <p class="lead-text">Our VPS Server plans include a wide range of additional features</p>

                        <div class="row g-4 text-start justify-content-center align-items-center">
                            <div class="col-md-6">
                                <div class="bg-style-one border shadow-sm">
                                    <div class="border-bottom p-3">
                                        <span class="h5 my-0 font-weight-normal d-flex"><i class="bi bi-hdd-network me-2"></i>Our standard VPS Server specifications</span>
                                    </div>
                                    <div class="p-3">
                                        <p class="d-flex align-items-center m-1 mr-0"><FontAwesomeIcon icon={faCheckCircle} color={'green'} /> Instant Provisioning</p>
                                        <p class="d-flex align-items-center m-1 mr-0"><FontAwesomeIcon icon={faCheckCircle} color={'green'} /> Free Entry Bandwidth</p>
                                        <p class="d-flex align-items-center m-1 mr-0"><FontAwesomeIcon icon={faCheckCircle} color={'green'} /> Create and deploy ISO on server</p>
                                        <p class="d-flex align-items-center m-1 mr-0"><FontAwesomeIcon icon={faCheckCircle} color={'green'} /> Includes SSD and RAID protection</p>
                                        <p class="d-flex align-items-center m-1 mr-0"><FontAwesomeIcon icon={faCheckCircle} color={'green'} /> Scale up on demand</p>
                                        <p class="d-flex align-items-center m-1 mr-0"><FontAwesomeIcon icon={faCheckCircle} color={'green'} /> Includes Basic DDos protection</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="bg-style-one border shadow-sm">
                                    <div class="border-bottom p-3">
                                        <span class="h5 my-0 font-weight-normal d-flex"><i class="bi bi-hdd-rack me-2"></i>Other VPS Server Specifications Included</span>
                                    </div>
                                    <div class="p-3">
                                        <p class="d-flex align-items-center m-1 mr-0"><FontAwesomeIcon icon={faCheckCircle} color={'green'} /> Full access to root server</p>
                                        <p class="d-flex align-items-center m-1 mr-0"><FontAwesomeIcon icon={faCheckCircle} color={'green'} /> Super Control Panels</p>
                                        <p class="d-flex align-items-center m-1 mr-0"><FontAwesomeIcon icon={faCheckCircle} color={'green'} /> Easy migration / scalability</p>
                                        <p class="d-flex align-items-center m-1 mr-0"><FontAwesomeIcon icon={faCheckCircle} color={'green'} /> Full Management Available</p>
                                        <p class="d-flex align-items-center m-1 mr-0"><FontAwesomeIcon icon={faCheckCircle} color={'green'} /> Choice of operating systems</p>
                                        <p class="d-flex align-items-center m-1 mr-0"><FontAwesomeIcon icon={faCheckCircle} color={'green'} /> Firewall and advanced networking made easy</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='container py-4'>
                    <div className="row justify-content-between mt-5 mb-5">
                        <div className="col-xl-5 col-lg-5 col-md-12 flex-column justify-content-start">
                            <span className="h2">Instant Activation</span>
                            <p className="mt-2">Once the payment for your service has been identified by the chosen payment method. Our control panel will activate your service right away.</p>
                            <div className="row mt-2">
                                <div className="col-md-6 d-flex flex-column rounded mt-3">
                                    <div className=" icon-rounded-box  bg-yellow-light mb-3">
                                        <FontAwesomeIcon icon={faClock} className="text-yellow" />
                                    </div>
                                    <span className="h5">Instant Activation</span>
                                    <span>Service is active instantly after payment confirmation.</span>
                                </div>
                                <div className="col-md-6 d-flex flex-column rounded mt-3">
                                    <div className=" icon-rounded-box  bg-purple-light mb-3">
                                        <FontAwesomeIcon icon={faCancel} className="text-purple" />
                                    </div>
                                    <span className="h5">Easy Cancellation</span>
                                    <span>Cancel your service instantly whenever you want.</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-5 col-lg-5 col-md-12 flex-column justify-content-end align-items-end">
                            <div className="bg-red-light rounded p-5">
                                <img src={imageDelivery} alt="clock" />
                            </div>

                        </div>

                    </div>
                </div>
                <div className='container'>

                    <div className="row mt-2 gy-4 box text-start justify-content-center align-items-center">
                        <div className="col-xl-4 col-lg-5">
                            <div className='d-flex flex-column rounded p-3 text-center justify-content-center align-items-center border bg-light'>
                                <div className="row">
                                    <div className="col-md-3 col-lg-2 col-3">

                                        <FontAwesomeIcon icon={faCamera} color={"#2f57a4"} size={'2xl'} />
                                    </div>
                                    <div className="col-md-9 col-lg-10 col-9">
                                        <h5 className="h5 mt-2">Snapshots</h5>
                                        <p>You can take a snapshot of your VPS at any time. Ideal before major updates, for example.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4 col-lg-5">
                            <div className='d-flex flex-column rounded p-3 text-center justify-content-center align-items-center border bg-light'>
                                <div className="row">
                                    <div className="col-md-3 col-lg-2 col-3">
                                        <FontAwesomeIcon icon={faCopy} color={"#2f57a4"} size={'2xl'} />
                                    </div>
                                    <div className="col-md-9 col-lg-10 col-9">
                                        <h5 className="h5 mt-2">Cloning</h5>
                                        <p>Do you want a new VPS with exactly the same configuration? With cloning, you create a 1-to-1 copy of your VPS.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4 col-lg-5">
                            <div className='d-flex flex-column rounded p-3 text-center justify-content-center align-items-center border bg-light'>
                                <div className="row">
                                    <div className="col-md-3 col-lg-2 col-3">

                                        <FontAwesomeIcon icon={faLock} color={"#2f57a4"} size={'2xl'} />
                                    </div>
                                    <div className="col-md-9 col-lg-10 col-9">
                                        <h5 className="h5 mt-2">Security</h5>

                                        <p>Your VPS is completely isolated in our network we offer a firewall feature.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4 col-lg-5">
                            <div className='d-flex flex-column rounded p-3 text-center justify-content-center align-items-center border bg-light'>
                                <div className="row">
                                    <div className="col-md-3 col-lg-2 col-3">

                                        <FontAwesomeIcon icon={faCloudArrowUp} color={"#2f57a4"} size={'2xl'} />
                                    </div>
                                    <div className="col-md-9 col-lg-10 col-9">
                                        <h5 className="h5 mt-2">Backups</h5>
                                        <p>Backups can be made daily, weekly or monthly. You can easily restore this yourself via our control panel. *Fee*</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4 col-lg-5">
                            <div className='d-flex flex-column rounded p-3 text-center justify-content-center align-items-center border bg-light'>
                                <div className="row">
                                    <div className="col-md-3 col-lg-2 col-3">
                                        <FontAwesomeIcon icon={faMailBulk} color={"#2f57a4"} size={'2xl'} />
                                    </div>
                                    <div className="col-md-9 col-lg-10 col-9">
                                        <h5 className="h5 mt-2">Mailing</h5>
                                        <p>By default port 25 is blocked on all our services. Please contact support if you want to make use of this option.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4 col-lg-5">
                            <div className='d-flex flex-column rounded p-3 text-center justify-content-center align-items-center border bg-light'>
                                <div className="row">
                                    <div className="col-md-3 col-lg-2 col-3">
                                        <FontAwesomeIcon icon={faHardDrive} color={"#2f57a4"} size={'2xl'} />

                                    </div>
                                    <div className="col-md-9 col-lg-10 col-9">
                                        <h5 className="h5 mt-2">Extra storage</h5>
                                        <p>It is possible to upgrade the storage of your VPS to up to 3TB of storage. Contact us for the possibilities.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='container'>

                    <div className="row my-5 m-0 pageTextBreak">
                        <div className="col-12 col-md-8 col-lg-8">
                            <div className='pageTextBreakBox'>
                                <h3 className="">Our customer service is happy to help</h3>
                                <h6>For example, look for frequently asked questions on our extensive FAQ page. On topics such as hosting, e-mail, domain names or WordPress. These pages are maintained by our expert customer service team and are therefore very up-to-date. Is your question not there? Our customer service is ready for you! Contact us via chat or email. </h6>
                            </div>
                        </div>
                        <div className="col-md-4 col-lg-4 d-none d-md-block d-lg-block">
                            <img alt='server plan image' src={serverPlanImage} className="img-fluid imageTextBreak mt-4" />
                        </div>
                    </div>

                    <div className="row my-5 m-0 g-2 justify-content-center d-flex">
                        <h3 className='text-center  mt-5 h3'>Scale globally with a few clicks!</h3>
                        <h6 className='text-center'>Centnodes easy-to-use interface allows you to deploy high performance servers worldwide from any kind of device!</h6>
                        <div className="col-md-8 mt-3">
                            <Tabs
                                defaultActiveKey="Desktop" id="deployServerTabs"
                                className="mb-3"
                                justify
                            >
                                <Tab eventKey="Desktop" title="Desktop">
                                    <img alt="deploy desktop" src={deployDesktopimage} className="img-fluid  mx-auto d-flex" />

                                </Tab>
                                <Tab eventKey="Tablet" title="Tablet">
                                    <img alt="deploy tablet" src={deployTabletimage} className="img-fluid  mx-auto d-flex" />

                                </Tab>
                                <Tab eventKey="Mobile" title="Mobile">
                                    <img alt="deploy mobile" src={deployMobileImage} className="img-fluid  mx-auto d-flex" />

                                </Tab>
                            </Tabs>
                        </div>

                    </div>
                </div >
            </div>
            <Footer />
            <div className="botomButtons d-none" id={'backToTopButton'}>
                <div className="row p-0 m-0">
                    <div className="col-2 backToTop justify-content-start">
                        <FontAwesomeIcon className="backToTopIcon" onClick={() => window.scrollTo(0, 0)} icon={faArrowUp} />
                    </div>
                </div>
            </div>
        </div >
    )
}



