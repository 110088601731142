import { faBoltLightning,  faCheckCircle, faDesktop,  faFlag,  faGear, faHeadset, faShoppingBasket, faUpload } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect } from 'react'
import Footer from '../../../components/Footer/Footer'
import { SiteNavigation } from '../../../components/Navigation/SiteNavigation'
import './GamingServices.css'
import serverHeaderImage from "../../../images/design/minecraft-server-header.svg";
import { Helmet } from 'react-helmet-async'
import minecraftBlock from '../../../images/design/minecraft-block.png'
export const MinecraftServers = () => {


    useEffect(() => {
    })
    return (
        <div>
            <Helmet>
                <title>Centnodes | Minecraft Servers</title>
                <meta
                    name="description"
                    content="Launch a Minecraft server in less than 5 minutes."
                />;
            </Helmet>
            <SiteNavigation />
            <div className="border-bottom">
                <div className="container pb-3">
                    <div className="row align-items-center mt-5 ">
                        <div className="col-md-5 d-flex flex-column">
                            <span className="h5">Minecraft Servers</span>
                            <span className="h2 mt-2">Top notch servers for your players.</span>
                            <p className="mt-2">Unleash your creativity with Centnodes Minecraft Server Hosting! We offer high-quality, reliable, and scalable solutions designed to cater to Minecraft enthusiasts. Our servers ensure a seamless experience, with excellent performance, unbeatable uptime, and an easy-to-use control panel.</p>
                            <div className="btn-group">
                                <a href="#packages" className="btn btn-primary rounded m-1">See packages</a>
                                <a href="/help" className="btn btn-outline-primary rounded m-1">Contact Us</a>
                            </div>
                        </div>
                        <div className="col-md-7 d-flex justify-content-center align-items-center mt-5 mt-xl-0">
                            <div className="content-img"><img src={serverHeaderImage} alt="server" /></div>
                        </div>
                    </div>
                </div>
            </div>
            <section className=" bg-light p-4 text-center bor" id="plans">
                <div className="container d-flex mt-5  flex-column align-items-center">
                    <h2 className="h2">Minecaft Servers</h2>
                    <p>Choose an ideal plan for your game server and put your work online today</p>
                </div>
                <div className="products-package py-5 mt-5">
                    <div className="container">
                        <div className="row">
                        <div className="col-lg-4">
                                <div className="card mb-5 border">
                                    <div id="packages" className="card-body text-center">
                                        <img className="img-fluid mb-4" src={minecraftBlock} width="80" />
                                        <h4 className="mb-3">Block Builder | 1GB</h4>
                                        <p className='mt-0 '>Perfect for newcomers setting their first footprints in the world of Minecraft.</p>

                                        <ul className="mb-4 mt-2">
                                            <li><FontAwesomeIcon color={'#51bbb5'} icon={faCheckCircle} />  AMD EPYC CPU</li>
                                            <li><FontAwesomeIcon color={'#51bbb5'} icon={faCheckCircle} />  5GB SSD Storage</li>
                                            <li><FontAwesomeIcon color={'#51bbb5'} icon={faCheckCircle} />  1GB RAM</li>
                                            <li><FontAwesomeIcon color={'#51bbb5'} icon={faCheckCircle} />  1 MYSQL Database</li>
                                            <li><FontAwesomeIcon color={'#51bbb5'} icon={faCheckCircle} />  1 Free subdomain</li>
                                        </ul>
                                        <h2 className="mb-4 h2">&euro; 1,99 <span className="month">/ mo</span></h2>
                                        <a href ="https://my.centnodes.com/products/gaming" className="btn btn-primary"><FontAwesomeIcon icon={faShoppingBasket} /> Order now</a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="card mb-5 border">
                                    <div className="card-body text-center">
                                        <img className="img-fluid mb-4" src={minecraftBlock} width="80" />
                                        <h4 className="mb-3">Redstone Engineer | 2GB</h4>
                                        <p className='mt-0 '>A choice for players who like to tinker with Minecraft's technical side.</p>

                                        <ul className="mb-4 mt-2">
                                            <li><FontAwesomeIcon color={'#51bbb5'} icon={faCheckCircle} />  AMD EPYC CPU</li>
                                            <li><FontAwesomeIcon color={'#51bbb5'} icon={faCheckCircle} />  10GB SSD Storage</li>
                                            <li><FontAwesomeIcon color={'#51bbb5'} icon={faCheckCircle} />  2GB RAM</li>
                                            <li><FontAwesomeIcon color={'#51bbb5'} icon={faCheckCircle} />  1 MYSQL Database</li>
                                            <li><FontAwesomeIcon color={'#51bbb5'} icon={faCheckCircle} />  1 Free subdomain</li>
                                        </ul>
                                        <h2 className="mb-4 h2">&euro; 3,99 <span className="month">/ mo</span></h2>
                                        <a href ="https://my.centnodes.com/products/gaming" className="btn btn-primary"><FontAwesomeIcon icon={faShoppingBasket} /> Order now</a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="card mb-5 border">
                                    <div className="card-body text-center">
                                        <img className="img-fluid mb-4" src={minecraftBlock} width="80" />
                                        <h4 className="mb-3">Nether Navigator | 4GB</h4>
                                        <p className='mt-0 '>For more audacious players ready to face the Nether's dangers.</p>

                                        <ul className="mb-4 mt-2">
                                            <li><FontAwesomeIcon color={'#51bbb5'} icon={faCheckCircle} />  AMD EPYC CPU</li>
                                            <li><FontAwesomeIcon color={'#51bbb5'} icon={faCheckCircle} />  15GB SSD Storage</li>
                                            <li><FontAwesomeIcon color={'#51bbb5'} icon={faCheckCircle} />  4GB RAM</li>
                                            <li><FontAwesomeIcon color={'#51bbb5'} icon={faCheckCircle} />  1 MYSQL Database</li>
                                            <li><FontAwesomeIcon color={'#51bbb5'} icon={faCheckCircle} />  1 Free subdomain</li>
                                        </ul>
                                        <h2 className="mb-4 h2">&euro; 9,99 <span className="month">/ mo</span></h2>
                                        <a href ="https://my.centnodes.com/products/gaming" className="btn btn-primary"><FontAwesomeIcon icon={faShoppingBasket} /> Order now</a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="card mb-5 border">
                                    <div className="card-body text-center">
                                        <img className="img-fluid mb-4" src={minecraftBlock} width="80" />
                                        <h4 className="mb-3">Ender Elite | 8GB</h4>
                                        <p className='mt-0 '> For seasoned players preparing for the End's challenging terrain.</p>

                                        <ul className="mb-4 mt-2">
                                            <li><FontAwesomeIcon color={'#51bbb5'} icon={faCheckCircle} />  AMD EPYC CPU</li>
                                            <li><FontAwesomeIcon color={'#51bbb5'} icon={faCheckCircle} />  20GB SSD Storage</li>
                                            <li><FontAwesomeIcon color={'#51bbb5'} icon={faCheckCircle} />  8GB RAM</li>
                                            <li><FontAwesomeIcon color={'#51bbb5'} icon={faCheckCircle} />  1 MYSQL Database</li>
                                            <li><FontAwesomeIcon color={'#51bbb5'} icon={faCheckCircle} />  1 Free subdomain</li>
                                        </ul>
                                        <h2 className="mb-4 h2">&euro; 14,99 <span className="month">/ mo</span></h2>
                                        <a href ="https://my.centnodes.com/products/gaming" className="btn btn-primary"><FontAwesomeIcon icon={faShoppingBasket} /> Order now</a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="card mb-5 border">
                                    <div className="card-body text-center">
                                        <img className="img-fluid mb-4" src={minecraftBlock} width="80" />
                                        <h4 className="mb-3">Diamond Digger | 12GB</h4>
                                        <p className='mt-0 '>A premium choice for players seeking extra resources and enhanced gameplay.</p>

                                        <ul className="mb-4 mt-2">
                                            <li><FontAwesomeIcon color={'#51bbb5'} icon={faCheckCircle} />  AMD EPYC CPU</li>
                                            <li><FontAwesomeIcon color={'#51bbb5'} icon={faCheckCircle} />  35GB SSD Storage</li>
                                            <li><FontAwesomeIcon color={'#51bbb5'} icon={faCheckCircle} />  12GB RAM</li>
                                            <li><FontAwesomeIcon color={'#51bbb5'} icon={faCheckCircle} />  1 MYSQL Database</li>
                                            <li><FontAwesomeIcon color={'#51bbb5'} icon={faCheckCircle} />  1 Free subdomain</li>
                                        </ul>
                                        <h2 className="mb-4 h2">&euro; 19,99 <span className="month">/ mo</span></h2>
                                        <a href ="https://my.centnodes.com/products/gaming" className="btn btn-primary"><FontAwesomeIcon icon={faShoppingBasket} /> Order now</a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="card mb-5 border">
                                    <div className="card-body text-center">
                                        <img className="img-fluid mb-4" src={minecraftBlock} width="80" />
                                        <h4 className="mb-3">Creeper Conqueror | 16GB</h4>
                                        <p className='mt-0 '>The ultimate package for those ready to face Minecraft's biggest challenges.</p>

                                        <ul className="mb-4 mt-2">
                                            <li><FontAwesomeIcon color={'#51bbb5'} icon={faCheckCircle} />  AMD EPYC CPU</li>
                                            <li><FontAwesomeIcon color={'#51bbb5'} icon={faCheckCircle} />  40GB SSD Storage</li>
                                            <li><FontAwesomeIcon color={'#51bbb5'} icon={faCheckCircle} />  16GB RAM</li>
                                            <li><FontAwesomeIcon color={'#51bbb5'} icon={faCheckCircle} />  1 MYSQL Database</li>
                                            <li><FontAwesomeIcon color={'#51bbb5'} icon={faCheckCircle} />  1 Free subdomain</li>
                                        </ul>
                                        <h2 className="mb-4 h2">&euro; 24,99 <span className="month">/ mo</span></h2>
                                        <a href ="https://my.centnodes.com/products/gaming" className="btn btn-primary"><FontAwesomeIcon icon={faShoppingBasket} /> Order now</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="bg-white border-top">
                <div className="container ">
                    <h4 className="h2 lead-title text-center">Why choose Centnodes?</h4>
                    <p className="lead-text text-center">Below we list some advantages of choosing Centnodes for your gaming server</p>
                    <div className="row gy-4 box text-start justify-content-center align-items-center">
                        <div className="col-lg-4">
                            <div className="d-flex flex-column rounded p-3 text-center justify-content-center align-items-center border bg-light">
                                <FontAwesomeIcon icon={faBoltLightning} size={'2xl'} className="lgg"></FontAwesomeIcon>
                                <span className="h5 mt-2">Performance</span>
                                <span>Experience seamless gameplay with our high-performance Minecraft servers, eliminating lag and downtime.</span>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="d-flex flex-column rounded p-3 text-center justify-content-center align-items-center border bg-light">
                                <FontAwesomeIcon icon={faGear} size={'2xl'} className="lgg"></FontAwesomeIcon>
                                <span className="h5 mt-2">Scalability</span>
                                <span>Whether you're a solo builder or running a massive multiplayer server, our scalable solutions meet your needs.</span>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="d-flex flex-column rounded p-3 text-center justify-content-center align-items-center border bg-light">
                                <FontAwesomeIcon icon={faDesktop} size={'2xl'} className="lgg"></FontAwesomeIcon>
                                <span className="h5 mt-2">Control</span>
                                <span>Enjoy full control over your Minecraft world with our easy-to-use control panel.</span>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="d-flex flex-column rounded p-3 text-center justify-content-center align-items-center border bg-light">
                                <FontAwesomeIcon icon={faUpload} size={'2xl'} className="lgg"></FontAwesomeIcon>
                                <span className="h5 mt-2">Uptime</span>
                                <span>Our reliable servers ensure your Minecraft worlds are accessible 24/7.</span>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="d-flex flex-column rounded p-3 text-center justify-content-center align-items-center border bg-light">
                                <FontAwesomeIcon icon={faHeadset} size={'2xl'} className="lgg"></FontAwesomeIcon>
                                <span className="h5 mt-2">Support</span>
                                <span>Get instant help when you need it from our dedicated support team.</span>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="d-flex flex-column rounded p-3 text-center justify-content-center align-items-center border bg-light">
                                <FontAwesomeIcon icon={faFlag} size={'2xl'} className="lgg"></FontAwesomeIcon>
                                <span className="h5 mt-2">Freedom</span>
                                <span>Create, explore, and survive without limits in your Minecraft worlds hosted by Centnodes.</span>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
            <section aria-label="Common questions" className="pt-5 border-bottom">
                <div className="container">
                    <div className="row g-4">
                        <div className="col-md-6">
                            <div className="text-left me-5">
                                <span className="h2">Common questions</span>
                                <p className="mt-4">To help you make an informed decision, we've compiled a list of our frequently asked questions related to the services listed on this page.</p>
                                <p>If you cannot find the answer to your question or would like more information on the matter, you can visit our complete Knowledgebase or contact us at any time and we will be happy to assist you.</p>
                                <div className="btn-groups">
                                    <a href="/knowledgebase" className="btn btn-primary mt-1 mb-1">Knowledgebase</a>
                                    <a href="/help" className="btn btn-outline-transparent mt-1 mb-1">Contact Us</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="text-left">
                                <span className="h3">Do you have any doubts?</span>
                                <p className="mt-3">See if your question has already been answered in the list below</p>
                                <div className="accordion accordion-flush" id="accordionQuestion">
                                    <div className="accordion-item border mt-1 mb-1">
                                        <h2 className="accordion-header" id="flush-headingThree">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">What performance can I expect from your Minecraft servers?</button>
                                        </h2>
                                        <div id="flush-collapseThree" className="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionQuestion">
                                            <div className="accordion-body">Our servers are designed to deliver high-performance, ensuring smooth and lag-free gameplay.</div>
                                        </div>
                                    </div>
                                    <div className="accordion-item border mt-1 mb-1">
                                        <h2 className="accordion-header" id="flush-headingTwo">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">How many players can your servers support?</button>
                                        </h2>
                                        <div id="flush-collapseTwo" className="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionQuestion">
                                            <div className="accordion-body">Our servers are scalable and can support from just a few players to a large multiplayer community.</div>
                                        </div>
                                    </div>

                                    <div className="accordion-item border mt-1 mb-1">
                                        <h2 className="accordion-header" id="flush-headingFour">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFour" aria-expanded="false" aria-controls="flush-collapseFour">How much control do I have over my Minecraft server?</button>
                                        </h2>
                                        <div id="flush-collapseFour" className="accordion-collapse collapse" aria-labelledby="flush-headingFour" data-bs-parent="#accordionQuestion">
                                            <div className="accordion-body">Our control panel gives you complete control over your Minecraft server, allowing you to manage every aspect of your game.</div>
                                        </div>
                                    </div>
                                    <div className="accordion-item border mt-1 mb-1">
                                        <h2 className="accordion-header" id="flush-headingFive">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFive" aria-expanded="false" aria-controls="flush-collapseFive">What is your server uptime?</button>
                                        </h2>
                                        <div id="flush-collapseFive" className="accordion-collapse collapse" aria-labelledby="flush-headingFive" data-bs-parent="#accordionQuestion">
                                            <div className="accordion-body">Our servers offer excellent uptime, ensuring your Minecraft world is accessible 24/7</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    )
}

