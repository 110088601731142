import React, { useEffect, useState } from 'react'
import { SiteNavigation } from '../../components/Navigation/SiteNavigation'
import './BlogItem.css'
import Footer from '../../components/Footer/Footer'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight, faArrowUp, faThumbsDown, faThumbsUp } from '@fortawesome/free-solid-svg-icons'
import parse from 'html-react-parser'
import apiService from '../../services/apiService'

const BlogItem = () => {
    const [blogItem, setBlogItem] = useState()

    const id = window.location.href.split("/blog").pop().replace('/', '');

    const getItem = async (id) => {
        let data = await apiService.getBlogItem(id);
        setBlogItem(data)
    }

    useEffect(() => {
        getItem(id)
    }, [id])

    return (
        <div>
            <SiteNavigation />
            <div className="border-bottom py-4 bg-test">
                <div className="container ">
                    <div className="row align-items-center mt-5 ">
                        <div className="col-md-5 d-flex flex-column">
                            <span className="h5">{blogItem && blogItem.title}</span>
                            <span><a className='text-white d-inline' href="#" onClick={() => window.location.href = '/blog'} > Blog </a><FontAwesomeIcon icon={faArrowRight} /> {blogItem && blogItem.title}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="row mt-4 pb-2">
                    <div className="d-none d-md-block d-lg-block col-md-3">
                        <div className="categoryBoxKnowledgeBase p-3 card">
                            <h6 className="mt-2">More Posts</h6>
                            <ul className="list-unstyled categoryList">
   

                            </ul>
                        </div>
                        <div className='categoryBoxKnowledgeBase p-3 card mt-3'>
                            <h6 className="mt-2">Did you like this item?</h6>
                            <div className='row mt-2 d-flex justify-content-center text-center'>
                                <div className='col-6'>
                                    <FontAwesomeIcon className='reviewIcon' color={'#2f57a4'} size={'2xl'} icon={faThumbsUp} />
                                </div>
                                <div className='col-6'>
                                    <FontAwesomeIcon className='reviewIcon' color={'#2f57a4'} size={'2xl'} icon={faThumbsDown} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-9">
                        <img src={blogItem && blogItem.image} className='img-fluid w-100 h-50' />
                        <div className="p-3 card">
                            <h3 className="mt-2 color-centnodes"> {blogItem && blogItem.title}</h3>
                            <div className="knowledgeBaseContent text-dark">
                                {blogItem && parse(blogItem.content)}
                            </div>
                        </div>
                    </div>
                    <div className="d-block d-md-none d-lg-none col-12">
                        <div className="categoryBoxKnowledgeBase p-3 card">
                            <h6 className="mt-2">More Posts</h6>
                            <ul className="list-unstyled categoryList">
                               

                            </ul>
                        </div>
                        <div className='categoryBoxKnowledgeBase p-3 card mt-3'>
                            <h6 className="mt-2 color-centnodes">Did you like this item?</h6>
                            <div className='row mt-2 d-flex justify-content-center text-center'>
                                <div className='col-6'>
                                    <FontAwesomeIcon className='reviewIcon' color={'#2f57a4'} size={'2xl'} icon={faThumbsUp} />
                                </div>
                                <div className='col-6'>
                                    <FontAwesomeIcon className='reviewIcon' color={'#2f57a4'} size={'2xl'} icon={faThumbsDown} />
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <Footer />
            <div className="botomButtons d-none" id={'backToTopButton'}>
                <div className="row p-0 m-0">
                    <div className="col-2 backToTop justify-content-start">
                        <FontAwesomeIcon className="backToTopIcon" onClick={() => window.scrollTo(0, 0)} icon={faArrowUp} />
                    </div>
                </div>
            </div>
        </div>
    )
}
export default BlogItem;
