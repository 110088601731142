import { faBoltLightning, faLock, faToolbox } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect, useState } from 'react'
import Footer from '../../components/Footer/Footer'
import { SiteNavigation } from '../../components/Navigation/SiteNavigation'
import './ControlFeature.css'
import controlPanelDesktop from '../../images/controlpanel_desktop.png'
import controlPanelPhone from '../../images/controlpanel_phone.png'
import deployDesktopimage from '../../images/deployserver_desktop.png'
import deployMobileImage from '../../images/deployserver_phone.png'
import deployTabletimage from '../../images/deployserver_tablet.png'
import {  Tab, Tabs } from 'react-bootstrap'
import serverHeaderImage from '../../images/design/server_controlpanel.svg'
import { Helmet } from 'react-helmet-async'
export const ControlFeature = () => {

    const [locations, setLocations] = useState();

    const getLocations = async () => {

        return;
    }


    useEffect(() => {
        // getLocations()
    })
    return (
        <div>
            <Helmet>
                <title>Centnodes | Our custom control panel</title>
                <meta
                    name="description"
                    content="Spend less time managing your infrastructure with our powerful control panel."
                />;
            </Helmet>
            <SiteNavigation />
            <div className="border-bottom py-4">
                <div className="container ">
                    <div className="row align-items-center mt-5 ">
                        <div className="col-md-5 d-flex flex-column">
                            <span className="h5">Control Panel</span>
                            <span className="h2 mt-2">Powerful Control Panel.</span>
                            <p className="mt-2">Spend less time managing your infrastructure with our powerful control panel.</p>
                        </div>
                        <div className="col-md-7 d-flex justify-content-center align-items-center mt-5 mt-xl-0">
                            <div className="content-img"><img src={serverHeaderImage} alt="server" /></div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="border-bottom bg-white pb-4">
                <div className='container'>
                    <div className="row d-flex justify-content-center">
                        <div className="col-10 col-md-8 col-lg-8 mb-2 mb-md-0 mb-lg-0">
                            <div className="controlPanelPreview">
                                <img src={controlPanelPhone} className="img-fluid phonePreview  mx-auto d-flex" />
                                <img src={controlPanelDesktop} className="img-fluid  mx-auto d-flex" />
                            </div>
                        </div>
                    </div>
                </div>
                <h3 className='text-center h3 mt-5'>Securely manage and control</h3>
                <h6 className='text-center'>As soon as you click deploy, the centnodes orchestration takes over and spins up your instance in your desired data center.</h6>

                <div className='container'>
                    <div className='row mt-5 m-0 make-equal'>
                        <div className='col-md-4  mt-2 mt-md-0 mt-lg-0 '>
                            <div className="border bg-light  rounded p-4 d-flex justify-content-center align-items-center flex-column m-2  aos-init" data-aos="fade-down-right">
                                <FontAwesomeIcon color={'#2f57a4'} className='m-3' icon={faLock} />
                                <h4 className="h4">Secure</h4>
                                <p>Our control panel is fully secured with the option to add 2FA Authentication to keep your servers as safe as possible!</p>
                            </div>

                        </div>
                        <div className='col-md-4 mt-2 mt-md-0 mt-lg-0 '>
                            <div className="border  bg-light  rounded p-4 d-flex justify-content-center align-items-center flex-column m-2  aos-init" data-aos="fade-down-left">
                                <FontAwesomeIcon color={'#2f57a4'} className='m-3' icon={faToolbox} />
                                <h4 className="h4">Easy</h4>
                                <p>Using a single portal, you can access servers, settings management, support, and much more.</p>
                            </div>
                        </div>
                        <div className='col-md-4 mt-2 mt-md-0 mt-lg-0 '>
                            <div className="border bg-light rounded p-4 d-flex justify-content-center align-items-center flex-column m-2  aos-init" data-aos="fade-down-right">
                                <FontAwesomeIcon color={'#2f57a4'} className='m-3' icon={faBoltLightning} />
                                <h4 className="h4">Fast</h4>
                                <p>Deploy, manage and re-install your servers with just a few clicks using our control panel.</p>
                            </div>
                        </div>
                    </div>

                    <div className="row my-5 m-0 g-2 justify-content-center d-flex">
                        <h3 className='text-center  mt-5 h3'>Scale globally with a few clicks!</h3>
                        <h6 className='text-center'>Centnodes easy-to-use interface allows you to deploy high performance servers worldwide from any kind of device!</h6>
                        <div className="col-md-8 mt-3">
                            <Tabs
                                defaultActiveKey="Desktop"
                                id="deployServerTabs"
                                className="pb2"
                                justify
                            >
                                <Tab eventKey="Desktop" title="Desktop">
                                    <img src={deployDesktopimage} className="img-fluid  mx-auto d-flex" />

                                </Tab>
                                <Tab eventKey="Tablet" title="Tablet">
                                    <img src={deployTabletimage} className="img-fluid  mx-auto d-flex" />

                                </Tab>
                                <Tab eventKey="Mobile" title="Mobile">
                                    <img src={deployMobileImage} className="img-fluid  mx-auto d-flex" />

                                </Tab>
                            </Tabs>
                        </div>
                    </div>
                </div>

            </div>
            <Footer />
        </div>
    )
}

