import { useEffect, useState } from 'react'
import Footer from '../../components/Footer/Footer'
import { SiteNavigation } from '../../components/Navigation/SiteNavigation'
import './LocationFeature.css'
import flag_nl from "../../images/flags/flag_nl.png"
import flag_gb from "../../images/flags/flag_gb.png"
import flag_de from "../../images/flags/flag_de.png"
import flag_es from "../../images/flags/flag_es.png"
import flag_fr from "../../images/flags/flag_fr.png"
import flag_pl from "../../images/flags/flag_pl.png"
import flag_se from "../../images/flags/flag_se.png"
import flag_us from "../../images/flags/flag_us.png"
import flag_ca from "../../images/flags/flag_ca.png"
import flag_in from "../../images/flags/flag_in.png"
import flag_kr from "../../images/flags/flag_kr.png"
import flag_jp from "../../images/flags/flag_jp.png"
import flag_sg from "../../images/flags/flag_sg.png"
import deployDesktopimage from '../../images/deployserver_desktop.png'
import deployMobileImage from '../../images/deployserver_phone.png'
import deployTabletimage from '../../images/deployserver_tablet.png'
import { Tab, Tabs } from 'react-bootstrap'
import serverHeaderImage from "../../images/design/world_home.svg";
import apiService from '../../services/apiService'
import { Helmet } from 'react-helmet-async'

export const LocationFeature = () => {


    const updateOS = async (osId) => {
        let elements = document.getElementsByClassName('osSelectionItem');

        for (var i = 0; i < elements.length; i++) {
            if (elements[i].dataset.key == osId) {
                elements[i].classList.add('osOptionSelected')
            } else {
                elements[i].classList.remove('osOptionSelected')

            }
        }
    }

    useEffect(() => {
    })
    return (
        <div>
            <Helmet>
                <title>Centnodes | Server locations</title>
                <meta
                    name="description"
                    content="Launch a SSD VPS on our worldwide KVM-based platform and experience the same great performance, in Stockholm or in London!."
                />;
            </Helmet>
            <SiteNavigation />
            <div className="border-bottom">
                <div className="container pb-3">
                    <div className="row align-items-center mt-5 ">
                        <div className="col-md-5 d-flex flex-column">
                            <span className="h5">Locations</span>
                            <span className="h2 mt-2">Top notch servers from The Netherlands to Germany.</span>
                            <p className="mt-2">Launch a SSD VPS on our worldwide KVM-based platform and experience the same great performance.</p>
                        </div>
                        <div className="col-md-7 d-flex justify-content-center align-items-center mt-5 mt-xl-0">
                            <div className="content-img"><img src={serverHeaderImage} alt="server" /></div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="border-bottom py-4 bg-white">
                <div className='container'>
                    <h3 className='text-center h3 mt-5'>Low latency in a location near you</h3>
                    <h6 className='text-center'>Launch a SSD VPS on our worldwide KVM-based platform and experience the same great performance, in Stockholm or in London!.</h6>
                    <div className='row  g-2 mt-5 make-equal '>
                        <div data-key={'ams'} key={'ams'} onClick={() => updateOS('ams')} className="col-6 col-md-4">
                            <div className="d-flex flex-column rounded p-3 text-center justify-content-center align-items-center border bg-white h-100">  <img src={flag_nl} className="img-fluid mx-auto d-flex" />
                                <div className='row text-center'>
                                    <div className="col-md-6">
                                        <p className="fw-bold">Netherlands</p>
                                    </div>
                                    <div className="col-md-6">
                                        <p className="fw-bold">Amsterdam</p>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div data-key={'fra'} key={'fra'} onClick={() => updateOS('fra')} className="col-6 col-md-4">
                            <div className="d-flex flex-column rounded p-3 text-center justify-content-center align-items-center border bg-white h-100">  <img src={flag_de} className="img-fluid mx-auto d-flex" />
                                <div className='row text-center'>
                                    <div className="col-md-6">
                                        <p className="fw-bold">Germany</p>
                                    </div>
                                    <div className="col-md-6">
                                        <p className="fw-bold">Frankfurt</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='container'>
                <div className="row my-5 m-0 g-2 justify-content-center d-flex">
                    <h3 className='text-center  mt-5 h3'>Scale globally with a few clicks!</h3>
                    <h6 className='text-center'>Centnodes easy-to-use interface allows you to deploy high performance servers worldwide from any kind of device!</h6>
                    <div className="col-md-8 mt-3">
                        <Tabs
                            defaultActiveKey="Desktop"
                            id="deployServerTabs"
                            className="pb2"
                            justify
                        >
                            <Tab eventKey="Desktop" title="Desktop">
                                <img src={deployDesktopimage} className="img-fluid  mx-auto d-flex" />

                            </Tab>
                            <Tab eventKey="Tablet" title="Tablet">
                                <img src={deployTabletimage} className="img-fluid  mx-auto d-flex" />

                            </Tab>
                            <Tab eventKey="Mobile" title="Mobile">
                                <img src={deployMobileImage} className="img-fluid  mx-auto d-flex" />

                            </Tab>
                        </Tabs>
                    </div>
                </div>

            </div>
            <Footer />
        </div>
    )
}

