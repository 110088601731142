import axios from "axios";

const apiUrl = (url) => {
    let res;
    switch (true) {
        case url.includes('localhost:3000'):
            res = 'http://localhost:8000/api'
            break;
        case url.includes('dev.centnodes.com'):
            res = 'https://api-dev.centnodes.com/api'
            break;
        default:
            res = 'https://api.centnodes.com/api'
            break;
    }
    return res;
}

const API_URL = apiUrl(window.location.href);


const getOperationSystems = async () => {
    try {
        const osList = await axios.get('https://api.centnodes.com/api' + '/oslist')

        return osList.data
    } catch (err) {
     console.log(err)
    }
}

const getKnowledgebaseItems = async () => {
    try {
        const items = await axios.get(API_URL + '/knowledgebase/')

        return items.data
    } catch (err) {
     console.log(err)
    }
}

const getBlogItems = async () => {
    try {
        const items = await axios.get(API_URL + '/blog/')

        return items.data
    } catch (err) {
     console.log(err)
    }
}

const getBlogItem = async (id) => {
    try {
        const item = await axios.get(API_URL + '/blog/' + id)

        return item.data
    } catch (err) {
     console.log(err)
    }
}

const getKnowledgebaseItem = async (id) => {
    try {
        const item = await axios.get(API_URL + '/knowledgebase/' + id)

        return item.data
    } catch (err) {
     console.log(err)
    }
}

export default {getBlogItem, getBlogItems, getKnowledgebaseItem, getOperationSystems, getKnowledgebaseItems };
